.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.link {
  align-items: normal;
  background-color: rgba(0,0,0,0);
  border-color: rgb(0, 0, 238);
  border-style: none;
  box-sizing: content-box;
  color: rgb(0, 0, 238); 
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  text-decoration: underline;
  transform-origin: 0 0;
  width: auto;
  -moz-appearance: none;
  -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */
}

/* Mozilla uses a pseudo-element to show focus on buttons, */
/* but anchors are highlighted via the focus pseudo-class. */

@supports (-moz-appearance:none) { /* Mozilla-only */
  button.link::-moz-focus-inner { /* reset any predefined properties */ 
    border: none;
    padding: 0;
  }
  button.link:focus { /* add outline to focus pseudo-class */
    outline-style: dotted;
    outline-width: 1px;
  }
}

button.play {
  width: 100%;
  height: 6em;
}

#root {
  padding: 0.5em;
}

div.top {
  height: 5%;
  display: flex;
  flex-flow: row nowrap;
}

.round::before {
  content: 'Round: '
}

.mistakes::before {
  content: 'Mistakes: ';
  margin-left: 2em;
}

.mistakes .fail::before {
  content: 'X';
  margin: 0.2em;
}

.last-play {
  height: 45%;
  font-size: 12em;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-play {
  height: 45%;
  font-size: 12em;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
  background: lightgrey;
}

.hand::before {
  content: 'and then: '
}

.hand .card {
  margin: 0.1em;
  font-size: 1.5em;
}
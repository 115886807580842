body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#game {
    display: flex;
    flex-flow: column;
    align-items: stretch;
}

#game header {
    background: lightblue;
    font-size: 8vh;
    line-height: 15vh;
    text-align: center;
}

#game .instructions {
    font-size: 5vh;
    text-align: center;
}

#game form {
    display: flex;
    flex-flow: column;
    align-items: stretch;
}

#game input, #game button {
    margin: 2vh;
    font-size: 4vh;
}

#game .word-prompt {
    font-size: 3vh;
    line-height: 20vh;
    text-align: center;
    text-decoration: underline;
}

#game .canvas {
    border: 1px solid black;
    align-self: center;
}

#sharing {
    display: flex;
    height: 100%;

    margin: auto;

    scroll-snap-type: x mandatory;
    overflow-x: scroll;
}

#sharing .series {
    scroll-snap-align: start;
    min-width: 100%;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

#sharing .series .words {
    scroll-snap-align: start;
    min-height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#sharing .series .drawing {
    scroll-snap-align: start;
    min-height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
}
